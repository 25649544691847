.stripo {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
}

/* .stripo__header {
  border-bottom: 1px solid #ccc;
} */

.stripo__content {
  display: flex;
  width: 100%;
}

#stripoSettingsContainer {
  flex: 0 0 400px;
  border-right: 1px solid #ccc;
}

#stripoPreviewContainer {
  flex: 1;
}
